import { PasswordlessUser, useUserStore, useWatchLogoutEvent } from './userStore';

import {
  UserManager as OidcUserManager,
  UserManagerSettings as OidcUserManagerSettings,
} from 'oidc-client-ts';

interface IUserProvider {
  authenticate(email: string): Promise<string | null>;
  verify(email: string, token: string): Promise<PasswordlessUser | null>;
}

export class AuthnUserProvider implements IUserProvider {
  async authenticate(email: string): Promise<string | null> {
    try {
      const res = await fetch(`/_auth/authenticate`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (!res.ok) throw new Error(await res.text());
      return null;
    } catch (e: unknown) {
      return e instanceof Error ? e.message : 'Error authenticating user';
    }
  }

  async verify(email: string, token: string) {
    const res = await fetch(`/_auth/verify`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ email, token }),
    });

    if (!res.ok) {
      if (res.status == 410) throw new Error('expired');
      return null;
    }

    const result = await res.json();

    const userStore = useUserStore();

    userStore.saveJWT(result.jwt);

    return userStore.user;
  }
}

export class OIDCUserProvider {
  private static clientID: string | null = null;
  private static authority: string | null = null;
  private oidcUserManager: OidcUserManager;
  private userStore: ReturnType<typeof useUserStore>;

  static init(clientID: string | null, authority: string | null) {
    this.clientID = clientID;
    this.authority = authority;
  }

  static isConfigured() {
    return !!this.clientID && !!this.authority;
  }

  constructor() {
    if (!OIDCUserProvider.isConfigured()) {
      throw new Error('OIDCUserProvider is not configured');
    }

    const oidcSettings: OidcUserManagerSettings = {
      authority: OIDCUserProvider.authority!,
      client_id: OIDCUserProvider.clientID!,
      redirect_uri: window.location.origin + '/oidc-login-callback',
    };

    this.oidcUserManager = new OidcUserManager(oidcSettings);

    this.userStore = useUserStore();
    useWatchLogoutEvent(() => this.logout());
  }

  async login() {
    const oidcUser = await this.oidcUserManager.signinPopup({
      scope: 'openid profile email',
    });

    const res = await fetch(`/_auth/oidc-verify`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ access_token: oidcUser.access_token }),
    });

    if (!res.ok) return null;
    const result = await res.json();

    this.userStore.saveJWT(result.jwt);
    return this.userStore.user;
  }

  async loginCallback() {
    await this.oidcUserManager.signinPopupCallback();
  }

  async logout() {
    await this.oidcUserManager.signoutPopup({
      post_logout_redirect_uri: window.location.origin + '/oidc-logout-callback',
    });
  }

  async logoutCallback() {
    await this.oidcUserManager.signoutPopupCallback();
  }
}
