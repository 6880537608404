import { WorkspaceRunnerData } from '@/services/runnerData';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getWorkspace } from '../services/api';

type WorkspaceStoreState = {
  workspace: WorkspaceRunnerData | null;
  loading: boolean;
};

export const useWorkspaceStore = defineStore('workspace', () => {
  const state = ref<WorkspaceStoreState>({
    workspace: null,
    loading: false,
  });

  const actions = {
    async fetch() {
      state.value.loading = true;
      state.value.workspace = await getWorkspace();
      state.value.loading = false;
    },
  };

  return { state, actions };
});
