import { OIDCUserProvider } from '@/auth';

export async function settingsProviderInit() {
  const res = await fetch('/_settings');
  if (!res.ok) throw new Error(await res.text());

  const providerConfig = await res.json();
  SetttingsProvider.init(providerConfig);
}

type SetttingsProviderCOnfig = {
  show_watermark: boolean;
  oidc_client_id: string | null;
  oidc_authority: string | null;
};

export class SetttingsProvider {
  static instance: SetttingsProvider | null = null;

  static init(config: SetttingsProviderCOnfig) {
    OIDCUserProvider.init(config.oidc_client_id, config.oidc_authority);
    SetttingsProvider.instance = new SetttingsProvider(config);
  }

  private constructor(private config: SetttingsProviderCOnfig) {}

  get showWatermark() {
    return this.config.show_watermark;
  }

  get isStagingRelease() {
    return import.meta.env.VITE_ABSTRA_RELEASE === 'staging';
  }

  get isLocal() {
    return location.origin.match(/http:\/\/localhost:\d+/);
  }
}
