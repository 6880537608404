import { CommonUserRoles } from '@/contracts.generated';
import { LocalBucket } from '@/models/bucket/repository';
import jwtDecode from 'jwt-decode';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { z } from 'zod';

export type AuthClaims = { email: string; exp?: number; iat?: number };

export type PasswordlessUser = {
  rawJwt: string;
  claims: AuthClaims;
};

export const useUserStore = defineStore('user', () => {
  const storage = new LocalBucket(z.string(), 'auth:jwt');
  const user = ref<PasswordlessUser | null>(null);

  const authHeaders = computed<Record<string, string>>(() =>
    user.value ? { Authorization: `Bearer ${user.value.rawJwt}` } : ({} as Record<string, string>),
  );
  const wsAuthHeaders = computed(() =>
    user.value ? ['default', `base64url.bearer.authorization.${user.value.rawJwt}`] : [],
  );

  const saveJWT = (jwt: string) => {
    storage.set(jwt);
    loadSavedToken();
  };

  const loadSavedToken = (): void => {
    const jwt = storage.get();
    if (!jwt) {
      return;
    }

    try {
      const claims = jwtDecode<AuthClaims>(jwt);
      if (claims.exp && claims.exp > Date.now() / 1000) {
        user.value = { rawJwt: jwt, claims };
      }
    } catch (e) {
      console.warn('Invalid JWT');
    }
  };

  const logout = () => {
    user.value = null;
    storage.remove();
  };

  const getRoles = async (): Promise<CommonUserRoles> => {
    const response = await fetch('/_user/my-roles', { headers: authHeaders.value });
    return response.json();
  };

  const signUp = async (): Promise<boolean> => {
    const response = await fetch('/_user/sign-up', {
      method: 'POST',
      headers: authHeaders.value,
    });
    return response.status === 200;
  };

  const allow = async (path: string) => {
    const response = await fetch(`/_access-control/allow${path}`, {
      headers: authHeaders.value,
    });
    return response.json();
  };

  loadSavedToken();
  return {
    loadSavedToken,
    saveJWT,
    user,
    logout,
    getRoles,
    authHeaders,
    wsAuthHeaders,
    signUp,
    allow,
  };
});

export const useWatchLogoutEvent = (callback: () => void) => {
  const store = useUserStore();
  watch(
    () => store.user,
    (user, prevUser) => {
      if (!user && prevUser) {
        callback();
      }
    },
  );
};
