import { AccessiblePageType } from '@/models/access-control';
import { makeAssetUrl } from '@/utils/assets';
import { isColor } from '@/widgets-lib/common/components/PlayerConfigProvider/colorHelpers';
import { SupportedLocales } from '@/widgets-lib/common/i18n';

export const DEFAULT_MAIN_COLOR = '#414a58';
export const DEFAULT_THEME_COLOR = '#FFFFFF';
export const DEFAULT_FONT_COLOR = '#000000';
export const DEFAULT_FORM_FONT_FAMILY = 'DM Sans';
export const DEFAULT_DASH_FONT_FAMILY = 'Inter';
export const DEFAULT_APPLICATION_NAME = 'Untitled Project';
export const DEFAULT_LANGUAGE = { value: 'en', label: 'English' } as const;

export type SidebarItem = {
  id: string;
  name: string;
  path: string;
  type: AccessiblePageType;
};

export type WorkspaceRunnerDTO = {
  name: string;
  main_color: string;
  logo_url: string;
  font_family: string;
  font_color: string;
  brand_name: string;
  sidebar: SidebarItem[];
  theme: string;
  language: SupportedLocales;
};

export type WorkspaceRunnerData = {
  name: string;
  mainColor: string;
  logoUrl: string | null;
  fontFamily: string;
  fontColor: string;
  brandName: string;
  sidebar: SidebarItem[];
  theme: string;
  language: SupportedLocales;
};

export type FormRunnerDTO = {
  id: string;
  path: string;
  title: string;
  is_initial: boolean;
  is_local?: boolean;
  start_message?: string | null;
  end_message?: string | null;
  error_message?: string | null;
  timeout_message?: string | null;
  start_button_text?: string | null;
  restart_button_text?: string | null;
  welcome_title?: string | null;
  auto_start?: boolean;
  allow_restart: boolean;
  workspace: WorkspaceRunnerDTO;
};

export type FormRunnerData = {
  id: string;
  path: string;
  title: string;
  isInitial: boolean;
  theme: string;
  brandName: string | null;
  isLocal: boolean;
  startMessage: string | null;
  endMessage: string | null;
  errorMessage: string | null;
  timeoutMessage: string | null;
  startButtonText: string | null;
  restartButtonText: string | null;
  welcomeTitle: string | null;
  logoUrl: string | null;
  mainColor: string;
  fontFamily: string;
  autoStart: boolean;
  allowRestart: boolean;
  runtimeType: 'form';
  language: SupportedLocales;
  sidebar: SidebarItem[];
};

export function runnerDTOtoData(dto: FormRunnerDTO): FormRunnerData {
  return {
    id: dto.id,
    path: dto.path,
    theme: dto.workspace.theme ?? DEFAULT_THEME_COLOR,
    brandName: dto.workspace.brand_name ?? null,
    title: dto.title,
    isInitial: dto.is_initial,
    isLocal: dto.is_local ?? false,
    startMessage: dto.start_message ?? null,
    endMessage: dto.end_message ?? null,
    errorMessage: dto.error_message ?? null,
    timeoutMessage: dto.timeout_message ?? null,
    startButtonText: dto.start_button_text ?? null,
    restartButtonText: dto.restart_button_text ?? null,
    logoUrl: dto.workspace.logo_url,
    mainColor: dto.workspace.main_color ?? DEFAULT_MAIN_COLOR,
    fontFamily: dto.workspace.font_family ?? DEFAULT_FORM_FONT_FAMILY,
    autoStart: dto.auto_start ?? false,
    allowRestart: dto.allow_restart,
    welcomeTitle: dto.welcome_title ?? null,
    runtimeType: 'form',
    language: dto.workspace.language ?? DEFAULT_LANGUAGE.value,
    sidebar: dto.workspace?.sidebar ?? [],
  };
}

export function workspaceDTOtoData(dto: Partial<WorkspaceRunnerDTO>): WorkspaceRunnerData {
  const theme = dto.theme ?? DEFAULT_THEME_COLOR;

  return {
    name: dto.name || DEFAULT_APPLICATION_NAME,
    fontColor: dto.font_color || DEFAULT_FONT_COLOR,
    sidebar: dto.sidebar || [],
    brandName: dto.brand_name || '',
    fontFamily: dto.font_family || DEFAULT_FORM_FONT_FAMILY,
    logoUrl: dto.logo_url ? makeAssetUrl('logo', dto.logo_url, 'player') : null,
    mainColor: dto.main_color || DEFAULT_MAIN_COLOR,
    theme: isColor(theme) ? theme : makeAssetUrl('background', theme, 'player'),
    language: dto.language || DEFAULT_LANGUAGE.value,
  };
}
