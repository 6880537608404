import { useUserStore } from '@/auth/userStore';
import { updateTitle } from '@/utils';
import {
  RouteLocationNormalized,
  RouteRecordRaw,
  Router,
  createRouter,
  createWebHistory,
} from 'vue-router';

type RouterMetadata = {
  title?: string;
  allowUnauthenticated?: boolean;
};

type RouterRecordWithMeta = RouteRecordRaw & { meta?: RouterMetadata };
type RouterRecordWithChildrenMeta = RouteRecordRaw & { children: RouterRecordWithMeta[] };
type RouterRecord = RouterRecordWithMeta | RouterRecordWithChildrenMeta;

export const routerRecords = [
  {
    path: '/oidc-login-callback',
    name: 'oidcLoginCallback',
    component: () => import('@/auth/OidcLoginCallback.vue'),
    meta: { allowUnauthenticated: true },
  },
  {
    path: '/oidc-logout-callback',
    name: 'oidcLogoutCallback',
    component: () => import('@/auth/OidcLogoutCallback.vue'),
    meta: { allowUnauthenticated: true },
  },
  {
    path: '/login',
    name: 'playerLogin',
    component: () => import('./views/Login.vue'),
    meta: { allowUnauthenticated: true },
  },
  {
    path: '/',
    name: 'main',
    component: () => import('./views/Main.vue'),
    redirect: { name: 'main' },
    children: [
      {
        path: '',
        name: 'playerHome',
        component: () => import('./views/Home.vue'),
        meta: { title: 'Home' },
      },
      {
        // Legacy route
        path: '_player/threads',
        redirect: { name: 'playerThreads' },
      },
      {
        path: 'threads',
        name: 'playerThreads',
        component: () => import('./views/Threads.vue'),
        meta: { title: 'Threads' },
      },
      {
        path: 'error/:status',
        name: 'error',
        component: () => import('./views/Error.vue'),
        meta: { allowUnauthenticated: true },
      },
      {
        path: ':path(.*)*',
        name: 'form',
        component: () => import('./views/Form.vue'),
        meta: { hideLogin: true },
      },
    ],
  },
] satisfies RouterRecord[];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routerRecords,
  scrollBehavior(to) {
    if (to.hash) return { el: to.hash };
  },
});

export const guardFactory =
  (router: Router) => async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    updateTitle(to, from);
    const meta = to.meta as RouterMetadata;
    if (meta.allowUnauthenticated) return;

    const userStore = useUserStore();
    const guard = await userStore.allow(to.path);
    const { redirect, ...query } = to.query;

    switch (guard.status) {
      case 'ALLOWED':
        break;

      case 'UNAUTHORIZED':
        await router.push({
          name: 'playerLogin',
          query: { ...query, redirect: redirect || to.path },
          params: to.params,
        });
        break;

      case 'NOT_FOUND':
        await router.push({
          name: 'error',
          params: { status: '404' },
        });
        break;

      default: // "FORBIDDEN"
        await router.push({
          name: 'error',
          params: { status: '403' },
        });
    }
  };

router.beforeEach(guardFactory(router));

export default router;
