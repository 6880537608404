import { isUrl } from './url';

export type ENVIROMENT = 'player' | 'editor';

export function makeAssetUrl(
  asset: 'logo' | 'background' | 'favicon',
  originalUrl: string,
  environment: ENVIROMENT,
) {
  if (isUrl(originalUrl)) return originalUrl;
  return environment === 'player' ? `/_assets/${asset}` : `/_editor/api/assets/${originalUrl}`;
}
