import { useUserStore } from '@/auth/userStore';
import { FormRunnerData, runnerDTOtoData, workspaceDTOtoData } from '@/services/runnerData';

export async function fetchRunnerData(path: string): Promise<FormRunnerData | null> {
  const userStore = useUserStore();

  const result = await fetch(`/_pages/${path}`, {
    headers: userStore.authHeaders,
  });

  if (result.status === 404) return null;
  if (!result.ok) throw new Error(await result.text());

  const { form } = await result.json();

  if (!form) return null;

  return runnerDTOtoData(form);
}

export async function getWorkspace() {
  const userStore = useUserStore();

  const result = await fetch('/_workspace', {
    headers: userStore.authHeaders,
  });

  if (result.status != 200) return workspaceDTOtoData({});
  const dto = await result.json();

  return workspaceDTOtoData(dto);
}
